import React, { createRef } from 'react';
import PropTypes from 'prop-types';

import { DropdownButton } from 'react-bootstrap';
import { BatchActionsToolbar } from 'pm_adminus';

import GroupingAction from './groupingAction';

class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actionsAvailable: false,
      selectedIds: [],
    };

    this.toolbar = createRef();
  }

  selectionChanged = (itemsMap) => {
    this.setState({
      actionsAvailable: itemsMap.size > 0,
      selectedIds: [...itemsMap.keys()],
    });
  }

  handleResetSelection = () => {
    window.localStorage.removeItem(this.toolbar.current.storageKey);
  }

  render() {
    const { actionsAvailable, selectedIds } = this.state;
    const {
      eventsGroupsList,
      setConfigurationsUrl,
      eventsGroupsUrl,
      table,
      storageKey,
    } = this.props;

    return (
      <BatchActionsToolbar
        ref={this.toolbar}
        selector={table}
        storageKey={storageKey}
        onChange={this.selectionChanged}
      >
        <DropdownButton
          id="bulk_actions_select"
          bsStyle="primary"
          title="Bulk Actions"
          disabled={!actionsAvailable}
        >
          <GroupingAction
            selectedIds={selectedIds}
            eventsGroupsList={eventsGroupsList}
            setConfigurationsUrl={setConfigurationsUrl}
            eventsGroupsUrl={eventsGroupsUrl}
            handleResetSelection={this.handleResetSelection}
          />
        </DropdownButton>
      </BatchActionsToolbar>
    );
  }
}

Toolbar.propTypes = {
  table: PropTypes.string.isRequired,
  storageKey: PropTypes.string.isRequired,

  eventsGroupsList: PropTypes.arrayOf(PropTypes.array).isRequired,
  setConfigurationsUrl: PropTypes.string.isRequired,
  eventsGroupsUrl: PropTypes.string.isRequired,
};

export default Toolbar;
