import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fa';

import {
  Panel,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';

class Dashboard extends React.Component {
  renderLinkedUsersPanel() {
    return (
      <Panel className="linked-users">
        <Panel.Heading>
          <h4 className="panel-title highlighted">Admin Linked Users</h4>
        </Panel.Heading>

        <Panel.Body>
          {this.renderLinkedUsersPanelContent()}
        </Panel.Body>
      </Panel>
    );
  }

  renderLinkedUsersPanelContent() {
    const { users } = this.props;

    if (!users.length) {
      return (
        <p>You don&apos;t have any linked users yet</p>
      );
    }

    return (
      <ButtonToolbar>{this.renderLinkedUsersList()}</ButtonToolbar>
    );
  }

  renderLinkedUsersList() {
    const { users } = this.props;

    return users.map(item => (
      <Button key={item.url} bsStyle="primary" href={item.url}>
        <Icon name="user" />
        &nbsp;
        {item.title}
      </Button>
    ));
  }

  render() {
    return (
      this.renderLinkedUsersPanel()
    );
  }
}

Dashboard.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Dashboard;
