import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fa';

import {
  Modal,
  Tabs,
  Tab,
  FormControl,
  FormGroup,
  HelpBlock,
  Form,
  Col,
  Panel,
  Table,
  MenuItem,
  Button,
} from 'react-bootstrap';

import { csrfToken } from 'pm_adminus';

class GroupingAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showGroupingModal: false,
      activeGroupingModalTab: 'assignGroup',
    };
  }

  handleGroupingModalShow = () => {
    this.setState({ showGroupingModal: true });
  }

  handleGroupingModalDismiss = () => {
    this.setState({ showGroupingModal: false });
  }

  handleSelectedModalTab = (activeGroupingModalTab) => {
    this.setState({ activeGroupingModalTab });
  }

  resetSelection = () => {
    const { handleResetSelection } = this.props;

    handleResetSelection();
  }

  assignGroupUrl() {
    const { setConfigurationsUrl } = this.props;

    return (`${setConfigurationsUrl}/assign_group`);
  }

  renderGroupingModal() {
    const { showGroupingModal } = this.state;

    return (
      <Modal show={showGroupingModal} onHide={this.handleGroupingModalDismiss}>
        <Modal.Header closeButton>
          <Modal.Title>Assign group to selected events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderGroupsAssigningSection()}
          <hr />
          {this.renderGroupsUnassigningSection()}
          <br />
          {this.renderAvailableGroupsTable()}
        </Modal.Body>
      </Modal>
    );
  }

  renderGroupsAssigningSection() {
    const { activeGroupingModalTab } = this.state;

    return (
      <Tabs
        justified
        animation={false}
        id="grouping_modal_tabs"
        activeKey={activeGroupingModalTab}
        onSelect={this.handleSelectedModalTab}
      >
        <Tab eventKey="assignGroup" title="Existing Group">
          {this.renderAssignExistingGroupTabContent()}
        </Tab>
        <Tab eventKey="createGroup" title="New Group">
          {this.renderAssignNewGroupTabContent()}
        </Tab>
      </Tabs>
    );
  }

  renderAssignExistingGroupTabContent() {
    const { selectedIds } = this.props;

    return (
      <Form
        horizontal
        action={this.assignGroupUrl()}
        method="POST"
        onSubmit={this.resetSelection}
      >
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <input type="hidden" name="set_configuration_ids" value={selectedIds} />
        <FormGroup>
          <Col sm={8}>
            <FormControl
              componentClass="select"
              name="group_name"
              onChange={this.handleGloupSelection}
            >
              {this.renderGroupsOptions()}
            </FormControl>
            <HelpBlock>Assignes selected events to chosen group.</HelpBlock>
          </Col>
          <Col sm={4}>
            <Button
              block
              type="submit"
              bsStyle="primary"
            >
              <Icon name="save" />
              &nbsp;
              Assign Group
            </Button>
          </Col>
        </FormGroup>
      </Form>
    );
  }

  renderAssignNewGroupTabContent() {
    const { selectedIds } = this.props;

    return (
      <Form
        horizontal
        action={this.assignGroupUrl()}
        method="POST"
        onSubmit={this.resetSelection}
      >
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <input type="hidden" name="set_configuration_ids" value={selectedIds} />
        <FormGroup>
          <Col sm={8}>
            <FormControl placeholder="New Group Name" name="group_name" />
            <HelpBlock>Assignes selected events to the group created or found by entered name.</HelpBlock>
          </Col>
          <Col sm={4}>
            <Button type="submit" bsStyle="success" block>
              <Icon name="plus" />
              &nbsp;
              Create & Assign
            </Button>
          </Col>
        </FormGroup>
      </Form>
    );
  }

  renderGroupsUnassigningSection() {
    const { selectedIds, setConfigurationsUrl } = this.props;

    return (
      <>
        <Form
          action={`${setConfigurationsUrl}/unassign_group`}
          method="POST"
          onSubmit={this.resetSelection}
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <input type="hidden" name="set_configuration_ids" value={selectedIds} />
          <FormGroup>
            <Button type="submit" block>
              <Icon name="refresh" />
              &nbsp;
              Unassign Group
            </Button>
            <HelpBlock>This action will completely unassign group from selected events</HelpBlock>
          </FormGroup>
        </Form>
      </>
    );
  }

  renderAvailableGroupsTable() {
    const { eventsGroupsList, eventsGroupsUrl } = this.props;
    const groupsList = Array.from(eventsGroupsList, ([groupId, groupName]) => (
      <tr key={groupId}>
        <td>{groupId}</td>
        <td>{groupName}</td>
        <td>
          <Button
            bsStyle="danger"
            bsSize="xsmall"
            href={`${eventsGroupsUrl}/${groupId}`}
            data-method="DELETE"
          >
            <Icon name="trash" />
            &nbsp;
            Delete
          </Button>
        </td>
      </tr>
    ));

    return (
      <Panel>
        <Panel.Heading>Available groups</Panel.Heading>
        <Table striped bordered condensed>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {groupsList}
          </tbody>
        </Table>
      </Panel>
    );
  }

  renderGroupsOptions() {
    const { eventsGroupsList } = this.props;

    return eventsGroupsList.map(item => <option key={item[1]} value={item[1]}>{item[1]}</option>);
  }

  render() {
    return (
      <>
        <MenuItem onClick={this.handleGroupingModalShow}>Group</MenuItem>

        {this.renderGroupingModal()}
      </>
    );
  }
}

GroupingAction.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventsGroupsList: PropTypes.arrayOf(PropTypes.array).isRequired,
  setConfigurationsUrl: PropTypes.string.isRequired,
  eventsGroupsUrl: PropTypes.string.isRequired,
  handleResetSelection: PropTypes.func,
};

GroupingAction.defaultProps = {
  handleResetSelection: () => {},
};

export default GroupingAction;
